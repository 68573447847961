import { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { isMobileContext } from "../../context/isMobileContext";
import CloseButton from "../common/CloseButton";
import { useMediaQuery } from "@mui/material";

const Summary = ({
  currency,
  totalAmount,
  paymentAmount,
  paymentTax,
  selectedPromoCode,
  bookingExtraIds,
  totalPrice,
  setTotalPrice,
  experienceData,
  confirm,
  selectedDay,
  images,
  allMembers,
  value,
  duration,
}) => {

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeIndex, setactiveIndex] = useState(0);
  const [collectiveAmount, setCollectiveAmount] = useState(null);
  const { isMobile } = useContext(isMobileContext);
  const [openItems, setOpenItems] = useState(false);
  const [date, setDate] = useState();
  const [discountAmount, setDiscountAmount] = useState(null);
  const [tieredDiscount, setTieredDiscount] = useState(null);
  const [extraPrice, setExtraPrice] = useState(0);
  const [amountWithPromo, setAmountWithPromo] = useState();
  const [totalPriceWithoutVat, setTotalPriceWithoutVat] = useState()
  const totalTickets = allMembers.reduce((sum, member) => {
    return sum + member.categoryNo;
  }, 0);

  const handleSummary = () => {
    if (openItems) {
      setOpenItems(false);
      document.body.classList.remove("no-scroll");
    } else {
      setOpenItems(true);
      document.body.classList.add("no-scroll");
    }
  };
  useEffect(() => {
    const date = new Date(value);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    setDate(
      `${day.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
      })} ${month} ${year}`
    );
  }, [value]);
  useEffect(() => {
    let experiencePrice = 0;
    let extraPrice = 0;
    let discount = 0;
    let promo = 0;
    if (selectedDay?.pricing[0].pricing_type == "booking") {
      experiencePrice = selectedDay?.pricing[0].rate;
    } else {
      experiencePrice = allMembers?.reduce(
        (total, category) =>
          total + category.categoryRate * category.categoryNo,
        0
      );
    }

    bookingExtraIds?.map(extra => {
      extraPrice += extra.extra_price
    });

    if (selectedDay?.discount?.length > 0) {
      if (selectedDay?.discount[0]?.type == "fixed") {
        if (selectedDay?.discount[0]?.amount_type == "percentage") {
          if (selectedDay?.discount[0]?.category[0].amount < 100) {
            setTieredDiscount(false);
            discount =
              (experiencePrice * selectedDay?.discount[0]?.category[0].amount) /
              100;
            setDiscountAmount(
              (experiencePrice * selectedDay?.discount[0]?.category[0].amount) /
              100
            );
          }
        } else {
          if (selectedDay?.discount[0]?.category[0].amount < experiencePrice)
            setTieredDiscount(false);
          setDiscountAmount(selectedDay?.discount[0]?.category[0].amount);
          discount = selectedDay?.discount[0]?.category[0].amount;
        }
      } else {
        selectedDay?.discount[0]?.category?.map(item => {
          if (
            allMembers?.reduce(
              (total, category) => total + category.categoryNo,
              0
            ) >= item.min_participants &&
            allMembers?.reduce(
              (total, category) => total + category.categoryNo,
              0
            ) <= item.max_participants
          ) {
            if (selectedDay?.discount[0]?.amount_type == "percentage") {
              if (item.amount < 100) {
                setTieredDiscount(item);
                setDiscountAmount((experiencePrice * item.amount) / 100);
                discount = (experiencePrice * item.amount) / 100;
              }
            } else {
              if (item.amount < experiencePrice) {
                setTieredDiscount(item);
                setDiscountAmount(item.amount);
                discount = item.amount;
              }
            }
          }
        });
      }
    } else {
      setDiscountAmount(null);
      setTieredDiscount(null);
    }
    if (selectedPromoCode) {
      if (selectedPromoCode.discount_type == "percentage") {
        if (selectedPromoCode.discount) {
          setAmountWithPromo(
            (experiencePrice * selectedPromoCode.discount) / 100
          );
          promo = (experiencePrice * selectedPromoCode.discount) / 100;
        }
      } else {
        if (selectedPromoCode.discount)
          setAmountWithPromo(selectedPromoCode.discount);
        promo = selectedPromoCode.discount;
      }
    }
    setExtraPrice(extraPrice);
    let all = experiencePrice + extraPrice;
    setTotalPrice &&
      setTotalPrice(all);
    setTotalPriceWithoutVat((all - all * 0.05));

    if (discount) {
      all = all - discount;
    } else if (discount == 0 && promo) {
      all = all - promo;
    }
    setCollectiveAmount(all);
  }, [selectedDay, bookingExtraIds, allMembers, selectedPromoCode]);



  useEffect(() => {
    if (!confirm) {
      const widgetSummary = {
        allMembers: allMembers,
        date: date,
        selectedDay: selectedDay,
        bookingExtraIds: bookingExtraIds,
        totalPrice: totalPrice,
        experienceData: experienceData,
        images: images,
        value: value,
        duration: duration,
        totalAmount: totalAmount,
        paymentAmount: paymentAmount,
        paymentTax: paymentTax,
      };
      sessionStorage.setItem("widget_summary", JSON.stringify(widgetSummary));
    }

  }, [
    totalAmount,
    paymentAmount,
    paymentTax,
    confirm,
    allMembers,
    date,
    selectedDay,
    bookingExtraIds,
    totalPrice,
    experienceData,
    images,
    duration,
    value,
  ]);

  const is2KDevice = useMediaQuery("(min-width: 2000px)");

  return (
    <>
      <div
        className={`summary-container ${isMobile && "mobile"} ${openItems && "opened"
          }`}
      >
        <div className="data-wrapper">
          <div className="booking-summary">
            <h4 className="medium">Booking Summary</h4>
            <div className="details-container">
              <div className="data">
                <div className="item">
                  <span className="bold">Experience</span>
                  <p className="medium">{experienceData?.name}</p>
                </div>
                <div className="item">
                  <span className="bold">Date</span>
                  <p className="medium">{date}</p>
                </div>
                {selectedDay?.scheduling_type != "pass" && (
                  <div className="item">
                    <span className="bold">Time</span>
                    <p className="medium">{selectedDay?.start_time}</p>
                  </div>
                )}
              </div>
              {allMembers?.reduce(
                (total, category) => total + category.categoryNo,
                0
              ) ? (
                selectedDay?.pricing[0].pricing_type == 'person' ?
                  <div className="data users-details">
                    <div className="item extra">
                      <span className="bold">Category</span>
                      {allMembers?.map(
                        (member, index) =>
                          member.categoryNo > 0 && (
                            <>
                              <div className="extra-data" key={index}>
                                <p className="medium">{member.categoryName}</p>
                              </div>
                            </>
                          )
                      )}
                    </div>
                    <div className="item extra">
                      <span className="bold">Tickets</span>
                      {allMembers?.map(
                        (member, index) =>
                          member.categoryNo > 0 && (
                            <>
                              <div className="" key={index}>
                                <p className="medium ">{member.categoryNo}</p>
                              </div>
                            </>
                          )
                      )}
                    </div>
                    <div className="item extra">
                      <span className="bold">Amount</span>
                      {allMembers?.map(
                        (member, index) =>
                          member.categoryNo > 0 && (
                            <>
                              <div className="" key={index}>
                                <p className="medium cap">
                                  {member.categoryRate > 0 &&
                                    !isNaN(
                                      +(member.categoryRate * member.categoryNo)
                                    )
                                    ? `${+(
                                      member.categoryRate * member.categoryNo
                                    )} ${currency}`
                                    : "-"}
                                </p>
                              </div>
                            </>
                          )
                      )}
                    </div>
                  </div>
                  :
                  <div className="data users-details">
                    <div className="item extra">
                      <span className="bold">Category</span>
                      <div className="extra-data">
                        <p className="medium">All</p>
                      </div>
                    </div>
                    <div className="item extra">
                      <span className="bold">Tickets</span>
                      <div className="" >
                        <p className="medium ">{allMembers?.reduce(
                          (total, category) => total + category.categoryNo,
                          0
                        )}</p>
                      </div>
                    </div>
                    <div className="item extra">
                      <span className="bold">Amount</span>
                      <div className="">
                        <p className="medium cap">{selectedDay?.pricing[0]?.rate} AED </p>
                      </div>
                    </div>
                  </div>
              ) : (
                ""
              )}
              {bookingExtraIds?.length > 0 && (
                <div className="data data-extras">
                  <div className="item-extra">
                    <span className="bold">Extras</span>
                    <span className="bold quantity-extra-title">Quantity</span>
                    <span className="bold">Amount</span>  
                  </div>
                  {bookingExtraIds
                    ?.sort((a, b) => a.extra_price - b.extra_price)
                    .map(extra => {
                      const optionCounts = extra.another_options.reduce((acc, option) => {
                        acc[option] = (acc[option] || 0) + 1;
                        return acc;
                      }, {});

                      return (
                        <div className="item-extra" key={extra.extra_id}>
                          <div className="extra-option-title">
                            <p className="medium">{extra.extra_title}</p>
                            <span className="medium">

                            {(extra.extra_type !== "booking" ? extra.extra_price / (extra.quantity) : extra.extra_price) > 0 
                              ? (
                               <>
                               {extra.extra_type === "booking" ? extra.extra_price + " AED " : '' }
                                  {extra.extra_type === "booking" ? "Per Booking" : ""}
                               </>
                              ) 
                              : " "
                              }
                            </span>
                          </div>
                          <div className={` medium ${is2KDevice ? 'quantity-extra':' ml-4'} `}>
                          {Object.entries(optionCounts).map(([option, count], optionIndex) => (
                            <p className="extra-quantity-option" key={`${extra.extra_id}-${optionIndex}`}>
                              {count} x {option}
                            </p>
                          ))}
                          </div>
                          {extra.extra_type === "booking" ? (
                            <p className="medium per-person-price">
                            {extra.extra_price !== 0
                              ? `${extra.extra_price} AED`
                              : "Price included"}
                          </p>
                          ) : (
                            <div className="per-person-price">
                            {Object.entries(optionCounts).map(([option, count], optionIndex) => (
                             
                                <p className="medium" key={`${extra.extra_id}-${optionIndex}`}>
                                    {extra.extra_price !== 0
                                      ? `${extra.extra_price / (extra.quantity) * count} AED`
                                      : "Price included"}
                                </p>
                              
                            
                        ))}
                        </div>
                          )}
                          
                        </div>
                      );
                    })}
                </div>
              )}
              {selectedPromoCode && (
                <div className="data">
                  <div className="item promo-code">
                    <p className="bold">Promo code</p>
                    <p className="promo">{selectedPromoCode?.code}</p>
                  </div>
                </div>
              )}
              <div className="data price">
                {!(selectedPromoCode || discountAmount) &&
                  <>
                    <div className="item promo-code">
                      <p className="bold">Amount before VAT</p>
                      <p className="">{totalPriceWithoutVat?.toFixed(2)} AED</p>
                    </div>
                    <div className="item promo-code">
                      <p className="bold">VAT Amount</p>
                      <p className="">
                        {(parseFloat((amountWithPromo || discountAmount) ? totalPrice - (amountWithPromo || discountAmount) : totalPrice * 0.05)).toLocaleString()} AED
                      </p>
                    </div>
                  </>
                }
                {(selectedPromoCode || discountAmount) && (
                  <>
                    <div className="item promo-code">
                      <p className="bold">Amount after discount</p>
                      <p className="">{totalPrice - (amountWithPromo || discountAmount)} AED</p>
                    </div>
                    <div className="item promo-code">
                      <p className="bold">Amount before VAT</p>
                      <p className="">{((totalPrice - (amountWithPromo || discountAmount)) - (totalPrice - (amountWithPromo || discountAmount)) * .05).toLocaleString()} AED</p>
                    </div>
                    <div className="item promo-code">
                      <p className="bold">VAT Amount</p>
                      <p className="">
                        {((totalPrice - (amountWithPromo || discountAmount)) * .05).toLocaleString()} AED
                      </p>
                    </div>
                  </>
                )}

              </div>
              <div className="data price">
                <div className="item promo-code">
                  <p className="bold">Total Amount</p>
                  <p className="">{(parseFloat((amountWithPromo || discountAmount) ? (totalPrice - (amountWithPromo || discountAmount)) : totalPrice))?.toFixed(3)} AED</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`footer`}>
          <div className="title">
            <p className="medium">Order Summary</p>
            <h1 className="bold">
            {experienceData?.name?.length > 30 ? `${experienceData?.name?.substring(0, 30)}...` : experienceData?.name}
            </h1>
          </div>
          <div className="total">
            <div className="item price">
              <span className="medium">Total amount</span>
              <p className="bold cap">
                {" "}
                {collectiveAmount?.toLocaleString()} {"AED"}
              </p>
            </div>
          </div>
        </div>
        <CloseButton icon onClick={handleSummary} />
      </div>
      {openItems && isMobile && <div className="website-overlay"></div>}
    </>
  );
};

export default Summary;
