import { useState, useEffect } from "react";
import Confirming from "../components/widget/Confirming/Confirming";
import Summary from "../components/widget/Summary";
import { useQuery } from "@tanstack/react-query";
import { cancelBooking, fetchDynamicData, getReasons } from "../utils/ApiHandler";
import PaymentSummary from "../components/widget/PaymentSummary";
import { toast } from "react-toastify";
import { customStyles } from "../utils/SelectOptions";
import Select from "react-select";
import { priceCategories } from "../utils/FixedData";
import CloseButton from "../components/common/CloseButton";
import Button from "../components/common/Button";
import { useNavigate } from "react-router-dom";


const Success = ({ id, cancel }) => {
    const { data, isSuccess, isLoading, isError } = useQuery(["booking-data"], () => fetchDynamicData("booking", id), {
        enabled: !!id,
        refetchOnWindowFocus: false
    })
    const [bookingData, setBookingData] = useState()
    const [reasons, setReasons] = useState([])
    const [selectedReason, setSelectedReason] = useState(null)
    const [reasonText, setReasonText] = useState('')
    const [bookingSummary, setBookingSummary] = useState()
    const [loading, setLoading] = useState(true)
    const [loadingCancel, setLoadingCancel] = useState(false)
    const [openCancel, setOpenCancel] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        if (isSuccess) {
            if (data.data == null) {
                toast.error(data?.message ? data?.message : `Payment link has been expired`, {
                    position: "top-right",
                    className: "toast-message",
                    autoClose: 2500,
                })
                setLoading(true)
            } else {
                setBookingData(data.data)
                setLoading(false)
                sessionStorage.getItem('widget_summary') && setBookingSummary(JSON.parse(sessionStorage.getItem('widget_summary')))
            }
        }
    }, [isSuccess, data])

    useEffect(() => {
        if (isError) {
            toast.error(`Network error, try again`, {
                position: "top-right",
                className: "toast-message",
                autoClose: 2500,
            })
        }
    }, [isError])

    useEffect(() => {
        const reason = []
        setLoading(true)
        getReasons(bookingData?.operator_id).then(data => {
            data.data.data.map(item => {
                reason.push({
                    label: item.name,
                    value: item.id
                })
            })
            setReasons([...reason])
            setLoading(false)
        })
    }, [bookingData])

    const handleCancelBooking = () => {
        setLoadingCancel(true)
        cancelBooking(bookingData?.id, {
            cancellation_reason_id: selectedReason?.value,
            reason_text: reasonText,
            booking_id: bookingData?.id
        }).then((data) => {
            toast.success(`Booking cancelled successfully`, {
                position: "top-right",
                className: "toast-message",
                autoClose: 2500,
            })
            navigate(`/?id=${bookingData?.start_time?.experience_id}`);
            document.body.classList.remove("no-scroll");

        }).catch(error => {
            toast.error(`Network error`, {
                position: "top-right",
                className: "toast-message",
                autoClose: 2500,
            })
            setLoadingCancel(false)
        })
    }

    if (isLoading || loading) {
        return <div className="loader-page success">
            <div className="submit-loading-container"></div>
        </div>
    }

    return (
        <div className="widget">
            <div className="summary-confrim-wrapper">
                <Confirming cancel={cancel} bookingData={bookingData} id={id} setOpenCancel={setOpenCancel} />
                <PaymentSummary
                    bookingData={bookingData}
                />
            </div >
            {openCancel &&
                <div className='modal-container cancel'>
                    <div className='cancel-wrapper'>
                        <CloseButton onClick={() => { setOpenCancel(false); document.body.classList.remove("no-scroll") }} />
                        <h4>Choose reason of cancellation</h4>
                        <div className="input-wrapper">
                            <Select
                                isSearchable={true}
                                options={reasons}
                                name={`reason`}
                                placeholder={`Select Reason*`}
                                className="select-drop-down"
                                noOptionsMessage={() => `no options`}
                                classNamePrefix="react-select"
                                styles={customStyles}
                                onChange={(e) => {
                                    setSelectedReason(e);
                                }}
                            />
                        </div>
                        {selectedReason && selectedReason.label == 'Other' &&
                            <div className="input-wrapper">
                                <input
                                    placeholder={'Enter a Reason'}
                                    value={reasonText}
                                    className={"input"}
                                    type="text"
                                    onChange={(e) => setReasonText(e.target.value)}
                                />
                            </div>}
                        <Button text={'Submit'} disabled={selectedReason == null || loadingCancel} onClick={() => { handleCancelBooking() }} />
                    </div>
                </div>
            }
        </div >
    );
}

export default Success;