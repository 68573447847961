import React, { useEffect, useState } from 'react';

const VerifyAppleWallet = () => {
    const [fileContent, setFileContent] = useState(null);

    useEffect(() => {
        const fetchFileContent = async () => {
            try {
                const response = await fetch('/.well-known/apple-developer-merchantid-domain-association');
                if (!response.ok) {
                    throw new Error('Failed to fetch file');
                }
                const content = await response.text();
                setFileContent(content);
            } catch (error) {
                console.error(error);
            }
        };

        fetchFileContent();
    }, []);
    
    useEffect(() => {
        if (fileContent) {
            // Create a blob from the file content
            const blob = new Blob([fileContent], { type: 'application/octet-stream' });
            // Create a URL for the blob
            const url = URL.createObjectURL(blob);
            // Create a link element
            const link = document.createElement('a');
            // Set attributes for downloading
            link.href = url;
            link.download = 'apple-developer-merchantid-domain-association';
            // Trigger the click event to initiate download
            link.click();
            // Cleanup the URL object after download
            URL.revokeObjectURL(url);
        }
    }, [fileContent]);

    return null; // This component doesn't render anything visible
}

export default VerifyAppleWallet;
