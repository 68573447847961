import { ErrorMessage, useFormik, Formik, Form, Field } from "formik";
import Button from '../../common/Button'
import * as Yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { API_URL } from "../../../utils/ApiHandler";

const PaymentForm = ({ setActiveStep }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false)
    const onSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        setLoading(true)
        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${API_URL}/booking/payment/success`
            }
        })
        if (error) {
            setLoading(false)
            toast.error(`Payment field please check your card`, {
                position: "top-right",
                className: "toast-message",
                autoClose: 2500,
            })
        }
        setLoading(false)
    };
    return (
        <>
            <div className="form-container payment">
                <h4 className="medium">Your Payment Information</h4>
                <form onSubmit={onSubmit} className="stripe-form">
                    <div className="stripe-form-elements">

                        <PaymentElement />
                    </div>
                    <div className="form-group button-group mobile-form">
                        {/* <Button text={'Previous'} type={'button'} onClick={() => { setActiveStep(1) }} /> */}
                        <div>
                            <svg width="150" height="34" viewBox="0 0 150 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_906_12779)">
                                    <path d="M146 0H3.73C2.74074 0 1.792 0.392981 1.09249 1.09249C0.392981 1.792 0 2.74074 0 3.73L0 30.27C0 31.2593 0.392981 32.208 1.09249 32.9075C1.792 33.607 2.74074 34 3.73 34H146C147.061 34 148.078 33.5786 148.828 32.8284C149.579 32.0783 150 31.0609 150 30V4C150 2.93913 149.579 1.92172 148.828 1.17157C148.078 0.421427 147.061 0 146 0V0ZM149 30C149 30.7956 148.684 31.5587 148.121 32.1213C147.559 32.6839 146.796 33 146 33H3.73C3.00677 32.9974 2.31391 32.7089 1.80251 32.1975C1.2911 31.6861 1.00263 30.9932 1 30.27V3.73C1.00263 3.00677 1.2911 2.31391 1.80251 1.80251C2.31391 1.2911 3.00677 1.00263 3.73 1H146C146.796 1 147.559 1.31607 148.121 1.87868C148.684 2.44129 149 3.20435 149 4V30Z" fill="#635BFF" />
                                    <path d="M17.07 11.24H12.77V22H14.69V17.84H17.07C19.47 17.84 20.97 16.68 20.97 14.54C20.97 12.4 19.47 11.24 17.07 11.24ZM16.97 16.24H14.69V12.94H17C18.38 12.94 19.11 13.53 19.11 14.59C19.11 15.65 18.35 16.19 17 16.19L16.97 16.24Z" fill="#635BFF" />
                                    <path d="M25.1002 14C24.5744 13.9939 24.0533 14.0979 23.5701 14.3052C23.0869 14.5125 22.6525 14.8185 22.2946 15.2037C21.9368 15.5889 21.6634 16.0446 21.4922 16.5417C21.3209 17.0388 21.2555 17.5662 21.3002 18.09C21.2533 18.6167 21.3166 19.1475 21.486 19.6484C21.6554 20.1493 21.9272 20.6095 22.2842 20.9997C22.6412 21.3898 23.0754 21.7014 23.5594 21.9145C24.0433 22.1277 24.5663 22.2378 25.0952 22.2378C25.624 22.2378 26.147 22.1277 26.6309 21.9145C27.1149 21.7014 27.5491 21.3898 27.9061 20.9997C28.2631 20.6095 28.5349 20.1493 28.7043 19.6484C28.8738 19.1475 28.937 18.6167 28.8902 18.09C28.9363 17.5667 28.8723 17.0395 28.7022 16.5424C28.5321 16.0454 28.2597 15.5895 27.9026 15.2041C27.5455 14.8188 27.1117 14.5125 26.629 14.3051C26.1463 14.0977 25.6255 13.9938 25.1002 14ZM25.1002 20.67C23.8802 20.67 23.1002 19.67 23.1002 18.09C23.1002 16.51 23.8602 15.51 25.1002 15.51C26.3402 15.51 27.1002 16.51 27.1002 18.09C27.1002 19.67 26.3102 20.66 25.1002 20.66V20.67Z" fill="#635BFF" />
                                    <path d="M36.7802 19.3499L35.3702 14.1299H33.8902L32.4902 19.3499L31.0702 14.1299H29.2202L31.5902 22.0099H33.1502L34.5902 16.8499L36.0302 22.0099H37.5902L39.9602 14.1299H38.1802L36.7802 19.3499Z" fill="#635BFF" />
                                    <path d="M44.0002 14C43.4814 14.0096 42.9699 14.1246 42.4968 14.3379C42.0238 14.5513 41.599 14.8585 41.2483 15.241C40.8976 15.6235 40.6283 16.0733 40.4567 16.563C40.2851 17.0528 40.2149 17.5723 40.2502 18.09C40.2084 18.6157 40.2768 19.1444 40.451 19.6422C40.6252 20.14 40.9014 20.596 41.2619 20.9809C41.6223 21.3659 42.0592 21.6714 42.5445 21.8778C43.0298 22.0843 43.5529 22.1872 44.0802 22.18C44.8052 22.2191 45.5242 22.0297 46.1359 21.6386C46.7477 21.2476 47.2213 20.6744 47.4902 20L46.0002 19.38C45.8833 19.7664 45.6385 20.1016 45.3059 20.3306C44.9734 20.5595 44.5729 20.6686 44.1702 20.64C43.8834 20.6468 43.5981 20.5954 43.3317 20.4888C43.0654 20.3821 42.8234 20.2226 42.6205 20.0197C42.4176 19.8168 42.2581 19.5748 42.1514 19.3085C42.0448 19.0421 41.9934 18.7568 42.0002 18.47H47.5202V17.87C47.5402 15.71 46.3202 14 44.0002 14ZM42.0702 17.13C42.1403 16.6708 42.3745 16.2526 42.7294 15.9529C43.0843 15.6532 43.5358 15.4923 44.0002 15.5C44.2209 15.4813 44.443 15.5099 44.6518 15.5837C44.8606 15.6576 45.0512 15.775 45.2111 15.9283C45.371 16.0815 45.4964 16.2671 45.579 16.4726C45.6616 16.678 45.6995 16.8987 45.6902 17.12L42.0702 17.13Z" fill="#635BFF" />
                                    <path d="M50.6901 15.3V14.13H48.8901V22H50.6901V17.87C50.6741 17.6031 50.7148 17.3359 50.8097 17.086C50.9045 16.8361 51.0513 16.6091 51.2403 16.4201C51.4293 16.2311 51.6563 16.0843 51.9062 15.9895C52.1561 15.8947 52.4233 15.8539 52.6901 15.87C52.9099 15.8544 53.1304 15.8544 53.3501 15.87V14.07C53.2101 14.07 53.0501 14.07 52.8401 14.07C52.4016 14.0472 51.9657 14.1511 51.5845 14.3692C51.2033 14.5873 50.8928 14.9104 50.6901 15.3Z" fill="#635BFF" />
                                    <path d="M57.4802 14C56.9613 14.0096 56.4499 14.1246 55.9768 14.3379C55.5038 14.5513 55.079 14.8585 54.7283 15.241C54.3776 15.6235 54.1083 16.0733 53.9367 16.563C53.7651 17.0528 53.6949 17.5723 53.7302 18.09C53.6884 18.6157 53.7568 19.1444 53.931 19.6422C54.1052 20.14 54.3814 20.596 54.7418 20.9809C55.1023 21.3659 55.5392 21.6714 56.0245 21.8778C56.5098 22.0843 57.0328 22.1872 57.5602 22.18C58.2785 22.2106 58.9886 22.0172 59.5923 21.6267C60.1961 21.2361 60.6635 20.6677 60.9302 20L59.3902 19.41C59.2733 19.7964 59.0284 20.1316 58.6959 20.3606C58.3634 20.5895 57.9629 20.6986 57.5602 20.67C57.2792 20.6674 57.0016 20.609 56.7434 20.4982C56.4851 20.3874 56.2516 20.2263 56.0562 20.0244C55.8608 19.8225 55.7074 19.5837 55.6051 19.322C55.5028 19.0603 55.4535 18.7809 55.4602 18.5H61.0002V17.9C61.0002 15.71 59.7602 14 57.4802 14ZM55.5502 17.13C55.6222 16.6744 55.8559 16.2599 56.2085 15.9624C56.561 15.6649 57.0089 15.5043 57.4702 15.51C57.6909 15.4913 57.913 15.5199 58.1218 15.5937C58.3305 15.6676 58.5212 15.785 58.6811 15.9383C58.8409 16.0915 58.9664 16.2771 59.049 16.4826C59.1316 16.688 59.1695 16.9087 59.1602 17.13H55.5502Z" fill="#635BFF" />
                                    <path d="M67.5601 15C67.2826 14.6744 66.9353 14.4154 66.5441 14.2423C66.1529 14.0692 65.7277 13.9864 65.3001 14C63.0901 14 61.8301 15.85 61.8301 18.09C61.8301 20.33 63.0901 22.18 65.3001 22.18C65.728 22.1958 66.1539 22.114 66.5455 21.9407C66.937 21.7675 67.284 21.5073 67.5601 21.18V22H69.3601V11.24H67.5601V15ZM67.5601 18.35C67.6004 18.6351 67.5787 18.9256 67.4964 19.2016C67.4141 19.4775 67.2732 19.7325 67.0833 19.949C66.8934 20.1654 66.659 20.3384 66.3961 20.4559C66.1332 20.5734 65.848 20.6328 65.5601 20.63C64.2501 20.63 63.5601 19.63 63.5601 18.11C63.5601 16.59 64.2601 15.59 65.5601 15.59C66.6701 15.59 67.5601 16.4 67.5601 17.88V18.35Z" fill="#635BFF" />
                                    <path d="M79.3102 14C78.8746 13.9809 78.4403 14.061 78.0402 14.2343C77.64 14.4075 77.2844 14.6693 77.0002 15V11.24H75.2002V22H77.0002V21.17C77.2792 21.4964 77.6282 21.7558 78.0211 21.9289C78.4141 22.102 78.841 22.1844 79.2702 22.17C81.4702 22.17 82.7302 20.31 82.7302 18.08C82.7302 15.85 81.5102 14 79.3102 14ZM79.0002 20.6C78.7122 20.6028 78.4271 20.5434 78.1642 20.4259C77.9013 20.3084 77.6669 20.1354 77.477 19.919C77.2871 19.7025 77.1462 19.4475 77.0639 19.1716C76.9816 18.8956 76.9599 18.6051 77.0002 18.32V17.85C77.0002 16.37 77.8402 15.56 79.0002 15.56C80.3002 15.56 81.0002 16.56 81.0002 18.08C81.0002 19.6 80.2502 20.6 79.0002 20.6Z" fill="#635BFF" />
                                    <path d="M86.9301 19.6599L85.0001 14.1299H83.1001L86.0001 21.7199L85.7001 22.4599C85.6491 22.7134 85.5019 22.9373 85.2893 23.0846C85.0768 23.2319 84.8154 23.2912 84.5601 23.2499C84.3604 23.2645 84.1598 23.2645 83.9601 23.2499V24.7599C84.2017 24.7958 84.4458 24.8125 84.6901 24.8099C85.3144 24.8553 85.9349 24.6799 86.443 24.3143C86.9511 23.9487 87.3147 23.4163 87.4701 22.8099L90.7101 14.1899H88.8201L86.9301 19.6599Z" fill="#635BFF" />
                                    <path d="M125 12.43C124.605 12.4277 124.214 12.5034 123.848 12.6527C123.483 12.802 123.15 13.0219 122.87 13.3L122.73 12.61H120.34V25.53L123.06 24.94V21.81C123.601 22.263 124.284 22.5109 124.99 22.51C126.93 22.51 128.71 20.92 128.71 17.4C128.71 14.18 126.91 12.43 125 12.43ZM124.35 20.06C124.111 20.0737 123.873 20.0342 123.651 19.9443C123.43 19.8543 123.231 19.7162 123.07 19.54V15.43C123.232 15.2473 123.433 15.1031 123.658 15.008C123.883 14.9128 124.126 14.8691 124.37 14.88C125.37 14.88 126.05 16.01 126.05 17.46C126.05 18.91 125.36 20.06 124.35 20.06Z" fill="#635BFF" />
                                    <path d="M133.73 12.4299C131.11 12.4299 129.52 14.6899 129.52 17.5399C129.52 20.9099 131.4 22.6199 134.08 22.6199C135.126 22.637 136.159 22.3857 137.08 21.8899V19.6399C136.243 20.062 135.317 20.2748 134.38 20.2599C133.3 20.2599 132.38 19.8699 132.24 18.5599H137.62C137.62 18.4099 137.62 17.8199 137.62 17.5599C137.71 14.6899 136.35 12.4299 133.73 12.4299ZM132.26 16.4999C132.26 15.2399 133.03 14.7099 133.71 14.7099C134.39 14.7099 135.11 15.2399 135.11 16.4999H132.26Z" fill="#635BFF" />
                                    <path d="M113 13.36L112.83 12.54H110.51V22.25H113.19V15.67C113.432 15.3762 113.758 15.1632 114.124 15.0595C114.49 14.9559 114.88 14.9665 115.24 15.09V12.54C114.831 12.3798 114.377 12.3747 113.964 12.5257C113.552 12.6768 113.209 12.9735 113 13.36Z" fill="#635BFF" />
                                    <path d="M99.46 15.4601C99.46 15.0201 99.82 14.8501 100.39 14.8501C101.335 14.8704 102.261 15.1174 103.09 15.5701V12.9401C102.232 12.5934 101.315 12.4201 100.39 12.4301C98.18 12.4301 96.71 13.6101 96.71 15.5901C96.71 18.6901 100.85 18.1901 100.85 19.5201C100.85 20.0401 100.41 20.2101 99.85 20.2101C98.7934 20.1484 97.766 19.8401 96.85 19.3101V22.0001C97.7941 22.4212 98.8162 22.6392 99.85 22.6401C102.11 22.6401 103.67 21.4901 103.67 19.4801C103.62 16.1201 99.46 16.7201 99.46 15.4601Z" fill="#635BFF" />
                                    <path d="M107.28 10.24L104.63 10.82V19.75C104.616 20.1263 104.679 20.5015 104.816 20.8526C104.952 21.2036 105.158 21.5233 105.422 21.7919C105.686 22.0605 106.002 22.2725 106.351 22.4148C106.699 22.5572 107.073 22.627 107.45 22.62C108.107 22.6494 108.761 22.5226 109.36 22.25V20C109.01 20.15 107.3 20.66 107.3 19V15H109.36V12.66H107.3L107.28 10.24Z" fill="#635BFF" />
                                    <path d="M116.25 11.7L118.98 11.13V8.96997L116.25 9.53997V11.7Z" fill="#635BFF" />
                                    <path d="M118.98 12.6101H116.25V22.2501H118.98V12.6101Z" fill="#635BFF" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_906_12779">
                                        <rect width="150" height="34" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <Button text={'Process Booking'} type={'submit'} disabled={loading}>
                            <svg width="12" height="15" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.375 7V4.75C3.375 2.28906 5.37891 0.25 7.875 0.25C10.3359 0.25 12.375 2.28906 12.375 4.75V7H13.5C14.7305 7 15.75 8.01953 15.75 9.25V16C15.75 17.2656 14.7305 18.25 13.5 18.25H2.25C0.984375 18.25 0 17.2656 0 16V9.25C0 8.01953 0.984375 7 2.25 7H3.375ZM5.0625 7H10.6875V4.75C10.6875 3.20312 9.42188 1.9375 7.875 1.9375C6.29297 1.9375 5.0625 3.20312 5.0625 4.75V7ZM1.6875 16C1.6875 16.3164 1.93359 16.5625 2.25 16.5625H13.5C13.7812 16.5625 14.0625 16.3164 14.0625 16V9.25C14.0625 8.96875 13.7812 8.6875 13.5 8.6875H2.25C1.93359 8.6875 1.6875 8.96875 1.6875 9.25V16Z" fill="white" />
                            </svg>
                        </Button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default PaymentForm;