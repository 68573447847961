import { useState, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { API_URL, fetchDynamicData, getClientScerect } from "../utils/ApiHandler";
import Payment from "../components/widget/Payment/Payment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom"
import PaymentSummary from "../components/widget/PaymentSummary";

const Payments = ({ id }) => {
    const { data, isSuccess, isLoading } = useQuery(["payment-data"], () => fetchDynamicData("booking", id), {
        enabled: !!id,
        refetchOnWindowFocus: false
    })
    const [clientID, setClientID] = useState(null)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();

    const [bookingData, setBookingData] = useState()

    useEffect(() => {
        if (isSuccess) {
            if (data.data != null) {
                setLoading(false)
                setBookingData(data.data)
            }
            else {
                toast.error(`Payment link has been expired`, {
                    position: "top-right",
                    className: "toast-message",
                    autoClose: 2500,
                })
            }
        }
    }, [isSuccess, data])

    useEffect(() => {
        if (bookingData) {
            getClientScerect(id).then(data => {
                if (data.data == null) {
                    toast.error(`Payment link has been expired, create new booking`, {
                        position: "top-right",
                        className: "toast-message",
                        autoClose: 2500,
                    })

                    navigate(`/?id=${bookingData?.start_time?.experience?.id}`)
                } else {
                    setLoading(false)
                    setClientID({clientSecretKey:data.data.client_secret,accountId:data.data.account_id})
                }
            }).catch(error => {
                toast.error(`Network error, try again`, {
                    position: "top-right",
                    className: "toast-message",
                    autoClose: 2500,
                })
            })
        }
    }, [id, bookingData, navigate])

    if (isLoading || loading || (clientID === null)) {
        return <div className="loader-page success">
            <div className="submit-loading-container"></div>
        </div>
    }

    return (
        <div className="widget">
            <div className="summary-confrim-wrapper">
                <Payment
                    clientID={clientID}
                />
                <PaymentSummary
                    bookingData={bookingData}
                />

            </div>
        </div >
    );
}

export default Payments;