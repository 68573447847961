import axios from "axios";

const customFetch = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_BASE_URL,
  headers: {
    Accept: "application/json",
  },
});

export default customFetch;
