import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Button from '../../common/Button';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { bookingExtras } from '../../../context/bookingExtras';
import moment from "moment";
import { sortTimes } from '../../../utils/HelperFunctions';
import WidgetSingleTime from './WidgetSingleTime';
const WidgetCalender = ({
    setActiveStep,
    specificDates,
    allMembers,
    setAllMembers,
    selectedTime,
    setSelectedTime,
    setSelectedMonth,
    monthStartTimes,
    selectedDay,
    setSelectedDay,
    value,
    setValue

}) => {

    const { setBookingExtraIds } = useContext(bookingExtras)
    const [disableAdd, setDisableAdd] = useState(false)
    const [disableStep, setDisableStep] = useState(false)
    const [sortedTimes, setSortedTimes] = useState([])

    const tileDisabled = ({ date }) => {
        return !specificDates.some((specificDate) =>
            isSameDay(specificDate, date)
        );
    };
    const isSameDay = (date1, date2) => {
        return (
            date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear()
        );
    };

    const handleStep = () => {
        if (!disableStep) {
            if (allMembers.reduce((total, category) => total + category.categoryNo, 0) == 0) {
                toast.error(`Please enter number of participants`, {
                    position: "top-right",
                    className: "toast-message",
                    autoClose: 2500,
                })
                return;
            } else {
                if (
                    allMembers.reduce((total, category) => total + category.categoryNo, 0) > selectedDay[selectedTime].remaining_seats) {
                    toast.error(selectedDay[selectedTime].remaining_seats == 0 ?
                        `No seats available`
                        : `Only ${selectedDay[selectedTime].remaining_seats} seats available`, {
                        position: "top-right",
                        className: "toast-message",
                        autoClose: 2500,
                    })
                }
                else {
                    if (allMembers.reduce((total, category) => total + category.categoryNo, 0) > selectedDay[selectedTime].max_booking_capacity) {
                        toast.error(`Max number of participants is ${selectedDay[selectedTime].max_booking_capacity}`, {
                            position: "top-right",
                            className: "toast-message",
                            autoClose: 2500,
                        })
                        return;
                    }
                    else if (allMembers.reduce((total, category) => total + category.categoryNo, 0) < selectedDay[selectedTime].min_booking_capacity) {
                        toast.error(`Min number of participants is ${selectedDay[selectedTime].min_booking_capacity}`, {
                            position: "top-right",
                            className: "toast-message",
                            autoClose: 2500,
                        })
                        return;
                    }
                    window.scrollTo(0, 0)
                    setActiveStep(1)
                }
            }
        }
    }

    const handleRemoveMembers = (member) => {
        setBookingExtraIds([])
        const members = [...allMembers]
        members.push({
            categoryName: member.categoryName,
            categoryNo: +member.categoryNo - 1,
            categoryRate: member.categoryRate,
            categoryID: member.categoryID,
            order: member.order,

        })
        setAllMembers([...new Map(members.map((item) => [item["categoryName"], item])).values(),])
    }

    const handleAddMembers = (member) => {
        setBookingExtraIds([])
        const members = [...allMembers]
        members.push({
            categoryName: member.categoryName,
            categoryNo: +member.categoryNo + 1,
            categoryRate: member.categoryRate,
            categoryID: member.categoryID,
            order: member.order,

        })
        setAllMembers([...new Map(members.map((item) => [item["categoryName"], item])).values(),])
    }

    useEffect(() => {
        if (selectedDay) {
            const times = []
            if (selectedDay[selectedTime].remaining_seats === 0) {
                setDisableStep(true)
                setDisableAdd(true)
            } else {
                setDisableStep(false)
                setDisableAdd(false)
                if (allMembers) {
                    if (allMembers.reduce((total, category) => total + category.categoryNo, 0)) {
                        const number = (allMembers.reduce((total, category) => total + category.categoryNo, 0))
                        if (
                            number >= selectedDay[selectedTime].remaining_seats
                            ||
                            number >= selectedDay[selectedTime].max_booking_capacity
                        ) {
                            setDisableAdd(true)
                        }
                        else {
                            setDisableAdd(false)
                        }
                    }
                }
            }
            if (selectedDay[0]?.scheduling_type == 'pass') {
                setSortedTimes([...selectedDay])
            }
            else {
                sortTimes(selectedDay).map(item =>
                    item?.start_time == "12:00 AM" &&
                    times.push(item)
                )
                sortTimes(selectedDay).map(item =>
                    item?.start_time != "12:00 AM" &&
                    times.push(item)
                )
                setSortedTimes([...times])
            }
        }
    }, [allMembers, selectedDay, selectedTime])

    const handleInput = (member, value) => {
        setBookingExtraIds([])
        const members = [...allMembers]
        members.push({
            categoryName: member.categoryName,
            categoryNo: parseInt(value, 10),
            categoryRate: member.categoryRate,
            categoryID: member.categoryID,
            order: member.order,

        })
        setAllMembers([...new Map(members.map((item) => [item["categoryName"], item])).values(),])
    }
    return (
        <div className="widget-calender">
            <p className='medium'>Choose Date</p>
            <Calendar
                // showNeighboringMonth={false}
                value={value}
                nextLabel={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M10.5 7.5L15 12L10.5 16.5" stroke="#211D33" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                </svg>}
                prevLabel={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M13.5 7.5L9 12L13.5 16.5" stroke="#211D33" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                </svg>}
                tileDisabled={tileDisabled}
                onClickDay={(value) => {
                    setBookingExtraIds([])
                    setAllMembers(current => current.map(item => ({ ...item, categoryNo: 0 })))
                    setValue(value); setSelectedDay(monthStartTimes[(new Date(value).getFullYear() +
                        '-' +
                        (new Date(value).getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2 }) +
                        '-' +
                        new Date(value).getDate().toLocaleString('en-US', { minimumIntegerDigits: 2 }))])
                }}
                onActiveStartDateChange={(value) => {
                    setSelectedTime(0)
                    setSelectedMonth(new Date(value.activeStartDate).getFullYear() + '-' + (new Date(value.activeStartDate).getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2 }))
                }}
            />
            {selectedDay &&
                <>
                    <div className='select-time'>
                        {selectedDay[0]?.scheduling_type == 'pass' && <span className='pass-ticket'>Single-entry ticket</span>}
                        {sortedTimes?.map((time, index) => (
                            <WidgetSingleTime
                                time={time}
                                key={index}
                                index={index}
                                selectedTime={selectedTime}
                                setSelectedTime={setSelectedTime}
                                setAllMembers={setAllMembers}
                            />
                        ))}
                    </div>
                    <div className='select-type'>
                        {selectedDay[selectedTime]?.pricing[0].pricing_type == 'booking' ?
                            <>
                                {allMembers?.map((member, index) => (
                                    <div className='type' key={index}>
                                        <p className='medium name'>
                                            {member.categoryName}
                                        </p>
                                        <div className='counter'>
                                            <div className={`sign ${member.categoryNo <= 0 && 'disabled'}`} onClick={() => { member.categoryNo > 0 && handleRemoveMembers(member) }}><span>-</span></div>
                                            {/* <div>
                                                <input
                                                    value={parseInt(member.categoryNo, 10)}
                                                    type='number'
                                                    onChange={(e) => handleInput(member, e.target.value)}
                                                // onBlur={() => validateInput(member)}
                                                />
                                            </div> */}
                                            <div>{parseInt(member.categoryNo, 10)}</div>
                                            <div className={`sign ${disableAdd && 'disabled'}`} onClick={() => { !disableAdd && handleAddMembers(member) }}><span>+</span></div>
                                        </div>
                                    </div>
                                ))}
                            </>
                            :
                            <>
                                {
                                    allMembers.filter(member => selectedDay[selectedTime]?.pricing.some(price => price.name === member.categoryName))
                                        .map((member, index) => (
                                            <div className='type' key={index}>
                                                <p className='medium name'>
                                                    {member.categoryName}
                                                    {<span>{member.categoryRate} {'AED'} for each</span>}
                                                </p>
                                                <div className='counter'>
                                                    <div className={`sign ${member.categoryNo <= 0 && 'disabled'}`} onClick={() => { member.categoryNo > 0 && handleRemoveMembers(member) }}><span>-</span></div>
                                                    {/* <div>
                                                        <input
                                                            value={parseInt(member.categoryNo, 10)}
                                                            type='number'
                                                            onChange={(e) => handleInput(member, e.target.value)}
                                                        // onBlur={() => validateInput(member)}
                                                        />
                                                    </div> */}
                                                    <div>{parseInt(member.categoryNo, 10)}</div>
                                                    <div className={`sign ${disableAdd && 'disabled'}`} onClick={() => { !disableAdd && handleAddMembers(member) }}><span>+</span></div>
                                                </div>
                                            </div>
                                        ))}
                            </>
                        }

                        {isNaN(allMembers.reduce((total, category) => total + category.categoryNo, 0)) && <p className='numbers-errors medium'>Please enter a valid member numbers.</p>}
                    </div>
                    <div className='button-section'>
                        <Button text={'Next'} customClass={`${isNaN(allMembers.reduce((total, category) => total + category.categoryNo, 0)) || disableStep && 'disabled'}`}
                            onClick={!isNaN(allMembers.reduce((total, category) => total + category.categoryNo, 0)) && handleStep}
                        />
                    </div>
                </>
            }
        </div>
    );
}

export default WidgetCalender;