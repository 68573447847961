import { API_URL, getTicketsPdf } from "../../../utils/ApiHandler";
import Button from "../../common/Button";

const Confirming = ({ bookingData, id, setOpenCancel, cancel }) => {
  
  return (
    <div className="confirm-booking-container">
      <div className="header">
        <img src="./icons/check-icon.svg" alt="icon" />
        <div className="header-content">
          <h1 className="medium">Booking Confirmed. {!cancel&&`Thank you!`}</h1>
          {!cancel &&
            <div className="header-description">

              <span className="medium">
                You will receive a confirmation email soon.
              </span>
            </div>
          }
        </div>
      </div>
      <div className="booking-details">
        <h2>Booking Details</h2>
        <div className="order-data">
          <div className="item">
            <span className="bold">Experience</span>
            <p className="medium">{bookingData?.experience_name}</p>
          </div>
          <div className="item">
            <span className="bold">Date</span>
            <p className="medium">
              {new Date(bookingData?.booking_day)?.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </p>
          </div>
          {bookingData?.start_time.start_time && (
            <div className="item">
              <span className="bold">Time Slot</span>
              <p className="medium">
                {bookingData?.start_time.start_time} to{" "}
                {bookingData?.start_time.end_time}
              </p>
            </div>
          )}
          <div className="item">
            <span className="bold">order id</span>
            <p className="medium">#{bookingData?.booking_id}</p>
          </div>
          {bookingData?.tickets[0].name == "booking" ? (
            <div className="item">
              <span className="bold">Tickets</span>
              <p className="medium">{bookingData?.tickets[0].no_of_tickets} </p>
            </div>
          ) : null}
        </div>
      </div>

      <div className="booking-details">
        <h2>Guest Information</h2>
        <div className="order-data">
          <div className="item">
            <span className="bold">Name</span>
            <p className="medium">
              {bookingData?.participent_info[0]?.contact?.first_name}{" "}
              {bookingData?.participent_info[0]?.contact?.last_name}{" "}
            </p>
          </div>
          <div className="item">
            <span className="bold">Email</span>
            <p className="medium email">
              {bookingData?.participent_info[0]?.contact?.email}{" "}
            </p>
          </div>
          <div className="item">
            <span className="bold">Mobile</span>
            <p className="medium">
              {bookingData?.participent_info[0]?.contact?.phone}{" "}
            </p>
          </div>
        </div>
      </div>
      
      {bookingData?.participent_info[0]?.extra?.length > 0 && (
        <div className="booking-details">
          <h2>Extras</h2>
          <div className="order-data">
            {bookingData?.participent_info[0]?.extra?.map((item, index) => {
              const optionCounts = item.another_options.reduce((acc, option) => {
                acc[option] = (acc[option] || 0) + 1;
                return acc;
              }, {});

              return Object.entries(optionCounts).map(([option, count], optionIndex) => (
                <div className="item" key={`${index}-${optionIndex}`}>
                  <span className="bold extra-name">{item?.name}</span>
                  <p className="medium">
                    {count} x {option}
                  </p>
                </div>
              ));
            })}
          </div>
        </div>
      )}

     

      <div className={`booking-details`} style={{ borderBottom: !cancel && "none", paddingBottom: !cancel && "0" }}>
        <h2>Payment Details</h2>
        <div className="order-data">
          <div className="item">
            <span className="medium">Payment Method</span>
            <p className="bold">{bookingData?.payment_type}  ****{bookingData?.payment_method_last4}</p>
          </div>
          {/* <div className="item">
            <span className="medium">Check-in Date</span>
            <p className="bold">{bookingData?.booking_day}</p>
          </div> */}
        </div>
      </div>
      {cancel &&
        <div className="booking-details print-details">
          <Button
            onClick={() => {
              setOpenCancel(true);
              document.body.classList.add("no-scroll");
            }}
            text="Cancel booking"
            customClass="red"
          />
        </div>
      }
    </div>
  );
};

export default Confirming;
