import React from 'react';
import IsMobileProvider from '../context/isMobileContext';
import BookingExtrasProvider from '../context/bookingExtras';

const Layout = ({ children }) => {
    return (
        <>
            <IsMobileProvider>
                <BookingExtrasProvider>
                    <main>{children}</main>
                </BookingExtrasProvider>
            </IsMobileProvider>
        </>
    )
}

export default Layout;