function convertTimeStringToDateTime(timeString) {
    const [time, period] = timeString?.split(' ');
    const [hours, minutes] = time?.split(':');

    let hours24 = parseInt(hours);
    if (period === 'PM' && hours24 !== 12) {
        hours24 += 12;
    } else if (period === 'AM' && hours24 === 12) {
        hours24 = 0;
    }

    const dateTime = new Date();
    dateTime.setHours(hours24, parseInt(minutes), 0);

    return dateTime;
}
export const calculateDuration = (selectedDay,setDuration) => {

    // Simulating API response
    const apiStartTime = selectedDay?.start_time;
    const apiEndTime = selectedDay?.end_time;

    const startDate = convertTimeStringToDateTime(apiStartTime);
    const endDate = convertTimeStringToDateTime(apiEndTime);

    if (endDate < startDate) {
        endDate.setDate(endDate.getDate() + 1); // Add one day to the end date
    }

    const startTimestamp = startDate.getTime();
    const endTimestamp = endDate.getTime();

    const hourDifference = Math.abs(endTimestamp - startTimestamp) / (1000 * 60 * 60);
    setDuration(hourDifference.toFixed(2))
}
export const buildFormData = (formData, data, parentKey) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
}
export const sortTimes = (times) => {
    const getTimeValue = (timeString) => {
        const [hours, minutes, ampm] = timeString?.split(/:| /);
        let hourValue = parseInt(hours);
        if (ampm.toLowerCase() === 'pm' && hourValue !== 12) {
            hourValue += 12;
        }
        return hourValue * 60 + parseInt(minutes);
    };
  
    return times.sort((a, b) => {
        const timeValueA = getTimeValue(a.start_time);
        const timeValueB = getTimeValue(b.start_time);
        if (timeValueA !== timeValueB) {
            return timeValueA - timeValueB;
        } else {
            // If times are equal in numeric value, sort AM before PM
            if (a.start_time.includes('am') && b.start_time.includes('pm')) {
                return -1;
            } else if (a.start_time.includes('pm') && b.start_time.includes('am')) {
                return 1;
            } else {
                return 0;
            }
        }
    });
  }