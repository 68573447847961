import moment from "moment";

const WidgetSingleTime = ({ time, index, selectedTime, setSelectedTime, setAllMembers }) => {
    return (
        <div className={`single-time ${time.scheduling_type == 'pass' && 'pass-time'} ${selectedTime === index && "active"}`} key={index} onClick={() => { setSelectedTime(index); setAllMembers(current => current.map(item => ({ ...item, categoryNo: 0 }))) }}>
            {time?.scheduling_type == 'pass' ?
                <>
                    <div className='once-time'>
                        <span className='medium times'>
                            <span style={{ minWidth: "7rem", textAlign: "center" }}>{time.pricing[0].title}</span>
                        </span>
                    </div>
                </>
                :
                time?.repeat == 'once' ?
                    <>
                        <div className='once-time'>
                            <span className='medium times'>
                                <span>Starts</span>
                                <span>{moment(new Date(time?.date_from)).format("DD-MM-YY")} {time?.scheduling_type !== 'pass' && `|| ${time.start_time}`}</span>

                            </span>
                            <span className='medium times'>
                                <span>Ends</span>
                                <span>{moment(new Date(time?.date_to)).format("DD-MM-YY")} {time?.scheduling_type != 'pass' && `|| ${time.end_time}`}    </span>

                            </span>
                        </div>
                    </> :
                    <>
                        <span className='medium'>{time.start_time} - {time.end_time}</span>
                    </>
            }
            {time.scheduling_type != 'pass' &&
                <div className={`time-tooltip medium`}>
                    {time.remaining_seats < time?.max_booking_capacity ?
                        `${time.remaining_seats} Remaining seats`
                        :
                        `Max ${time?.max_booking_capacity} seats`
                    }
                </div>
            }
        </div>
    );
}

export default WidgetSingleTime;