import './App.scss';
import Layout from './components/Layout';
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Widget from './components/widget/Widget';
import { BrowserRouter, Route, Routes, useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Success from './pages/Success';
import Payments from './pages/Payments';
import VerifyAppleWallet from './VerifyAppleWallet';
import { useEffect } from 'react';

function App() {
  const queryClient = new QueryClient()
  let location = useLocation();

  // Extract and parse query parameters using URLSearchParams
  let queryParams = new URLSearchParams(location.search);
  let id = queryParams.get('id');
  let cancel = queryParams.get('cancel');
  
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <Layout>
          <Routes>
            <Route path={``} exact element={<Widget id={id}/>} />
            <Route path={`/success`} exact element={<Success id={id} cancel={cancel}/>} />
            <Route path={`/payment`} exact element={<Payments id={id}/>} />
            <Route path={`/.well-known/apple-developer-merchantid-domain-association`} exact element={<VerifyAppleWallet/>} />
          </Routes>
        </Layout>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

export default App;
